import server from "./server";

const getSkills = async () => {
  try {
    const skills = await server.get("/skills");
    return skills.data;
  } catch (error) {
    return error.response.data;
  }
};

const getSkill = async (id) => {
  try {
    const skill = await server.get(`/skills/${id}`);
    return skill.data;
  } catch (error) {
    return error.response.data;
  }
};

const addSkill = async (data) => {
  try {
    const skill = await server.post("/skills", data);
    return skill.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateSkill = async (id, data) => {
  try {
    const skill = await server.put(`/skills/${id}`, data);
    return skill.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteSkill = async (id) => {
  try {
    const removeSkill = await server.delete(`/skills/${id}`);
    return removeSkill.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getSkills, getSkill, addSkill, updateSkill, deleteSkill };
