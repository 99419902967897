import server from "./server";

const login = async (data) => {
  try {
    const login = await server.post("/login", data);
    return login.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export { login };
