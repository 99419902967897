import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

// Custom Components
import EmbeddedCode from '../../components/Embedded/Embedded';

class Embedded extends Component {
  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Embedded`;
  }

  render() {
    // Import Styling
    require('./Embedded.scss');

    return (
      <div className='codes'>
        <Header />
        <div className='container'>
          <Card title='Embedded' className='p-p-4 p-my-5'>
            <Button
              label='Add New'
              icon='pi pi-plus'
              className='primary-color'
              onClick={() => this.props.router.navigate('/codes/add')}
            />

            <EmbeddedCode />
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Embedded;
