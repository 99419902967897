// Assets
import ankitShahLogo from '../../../assets/img/ankit-shah-logo.png';

const Footer = () => {
  // Import Styling
  require('./Footer.scss');

  return (
    <footer>
      Website of
      <a href='https://ankitshah.me/' target='_blank' rel='noreferrer'>
        <img width='100' src={ankitShahLogo} alt='Ankit Shah' />
      </a>
    </footer>
  );
};

export default Footer;
