import server from "./server";

const getAdmins = async () => {
  try {
    const admins = await server.get("/admins");
    return admins.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAdmin = async (id) => {
  try {
    const admin = await server.get(`/admins/${id}`);
    return admin.data;
  } catch (error) {
    return error.response.data;
  }
};

const addAdmin = async (data) => {
  try {
    const admin = await server.post("/admins", data);
    return admin.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateAdmin = async (id, data) => {
  try {
    const admin = await server.put(`/admins/${id}`, data);
    return admin.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAdmin = async (id) => {
  try {
    const removeAdmin = await server.delete(`/admins/${id}`);
    return removeAdmin.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getAdmins, getAdmin, addAdmin, updateAdmin, deleteAdmin };
