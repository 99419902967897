import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

// Server API
import { getArticles, deleteArticle } from '../../api/blog';
import { withRouter } from '../../helpers/routes/withRouter';

class Blog extends Component {
  state = {
    data: [],
    globalFilter: '',
  };

  getArticles = async () => {
    const articles = await getArticles();

    if (!articles.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: articles?.message,
      });
    }

    this.setState({ data: articles.data });
  };

  deleteArticle = async (id) => {
    const removeArticle = await deleteArticle(id);

    this.toast.show({
      severity: removeArticle.success ? 'success' : 'error',
      summary: removeArticle.success ? 'Done' : 'Error',
      detail: removeArticle?.message,
    });

    await this.getArticles();
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Blog`;
    await this.getArticles();
  }

  render() {
    // Import Styling
    require('./Blog.scss');

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this article?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteArticle(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Articles
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const imageBodyTemplate = (rowData) => {
      return (
        <img
          src={`${process.env.REACT_APP_CONTENT_BASE_URL}${rowData?.thumbnail}`}
          alt={rowData?.path}
          className='thumb-image'
        />
      );
    };

    const actionBodyTemplate = (rowData) => (
      <>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />

        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-primary p-ml-2 primary-color'
          onClick={() =>
            this.props.router.navigate(`/blog/edit/${rowData._id}`)
          }
        />
      </>
    );

    return (
      <div className='blog'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Blog' className='p-p-4 p-my-5'>
            <Button
              label='Add New'
              icon='pi pi-plus'
              className='primary-color'
              onClick={() => this.props.router.navigate('/blog/add')}
            />

            <div className='datatable-responsive-demo'>
              <DataTable
                ref={(el) => (this.dt = el)}
                paginator
                rows={15}
                rowsPerPageOptions={[15, 30, 60]}
                className='p-mt-3 p-datatable-responsive-demo'
                emptyMessage='No articles found.'
                value={this.state.data}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column field='title' header='Title' sortable filter />
                <Column
                  body={imageBodyTemplate}
                  field='path'
                  header='Thumbnail'
                />
                <Column header='Actions' body={actionBodyTemplate} />
              </DataTable>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Blog);
