import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Custom Components
import Files from '../../components/Files/Files';

// Components
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ColorPicker } from 'primereact/colorpicker';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

// Server API
import { getInfo, updateInfo } from '../../api/info';
import { getObjectByName } from '../../helpers/helper';

class Info extends Component {
  state = {
    // Collect Form Data
    fullname: '',
    job_title: '',
    description: '',
    full_description: '',
    email: '',
    social_media: '',
    phone: '',
    map: '',
    cv: '',
    site_color: '',
    site_title: '',
    profile_picture: '',
    logo: '',

    // Dialog
    display: false,
    type: '',

    // Loading
    loading: false,
  };

  getGeneralInfo = async () => {
    const info = await getInfo();
    const data = info.data;

    if (!info.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: info?.message,
      });
    }

    this.setState({
      fullname: getObjectByName(data, 'option_name', 'fullname').option_value,
      job_title: getObjectByName(data, 'option_name', 'job_title').option_value,
      description: getObjectByName(data, 'option_name', 'description')
        .option_value,
      full_description: getObjectByName(data, 'option_name', 'full_description')
        ?.option_value,
      email: getObjectByName(data, 'option_name', 'email').option_value,
      social_media: JSON.parse(
        getObjectByName(data, 'option_name', 'social_media').option_value
      ),
      phone: getObjectByName(data, 'option_name', 'phone').option_value,
      map: getObjectByName(data, 'option_name', 'map').option_value,
      cv: getObjectByName(data, 'option_name', 'cv').option_value,
      site_color: getObjectByName(data, 'option_name', 'site_color')
        .option_value,
      site_title: getObjectByName(data, 'option_name', 'site_title')
        .option_value,
      profile_picture: getObjectByName(data, 'option_name', 'profile_picture')
        .option_value,
      logo: getObjectByName(data, 'option_name', 'logo').option_value,
    });
  };

  saveSettings = async () => {
    this.setState({ loading: true });

    const info = await updateInfo({
      fullname: this.state.fullname,
      job_title: this.state.job_title,
      description: this.state.description,
      full_description: this.state.full_description,
      email: this.state.email,
      social_media: JSON.stringify(this.state.social_media),
      phone: this.state.phone,
      map: this.state.map,
      cv: this.state.cv,
      site_color: this.state.site_color,
      site_title: this.state.site_title,
      profile_picture: this.state.profile_picture,
      logo: this.state.logo,
    });

    this.toast.show({
      severity: info.success ? 'success' : 'error',
      summary: info.success ? 'Saved' : 'Error',
      detail: info?.message,
    });

    this.setState({ loading: false });
  };

  setFile = (type, path) => {
    this.setState({
      logo: type === 'logo' ? path : this.state.logo,
      profile_picture: type === 'pp' ? path : this.state.profile_picture,
      cv: type === 'cv' ? path : this.state.cv,
    });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | General Info`;
    await this.getGeneralInfo();
  }

  render() {
    // Import Styling
    require('./Info.scss');

    // CV Extension
    const cvName = this.state.cv.split('/').pop();
    const ext = cvName.split('.').pop();

    const footer = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ display: false })}
        />
      </div>
    );

    return (
      <div className='info'>
        <Header />
        <Toast ref={(el) => (this.toast = el)} position='top-right' />

        <Dialog
          header='Select File'
          maximizable
          visible={this.state.display}
          style={{ width: '75vw' }}
          footer={footer}
          onHide={() => this.setState({ display: false })}
          className='upload-file-modal'
        >
          <Files
            popup={true}
            setFile={this.setFile}
            type={this.state.type}
            imagesOnly={this.state.type !== 'cv'}
          />
        </Dialog>

        <div className='container info-form'>
          <Card className='p-my-5 p-px-4 p-pt-4' title='General Info'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='fullname' className='p-d-block'>
                    Full Name
                  </label>
                  <InputText
                    value={this.state.fullname}
                    onChange={(e) =>
                      this.setState({ fullname: e.target.value })
                    }
                    id='fullname'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='job_title' className='p-d-block'>
                    Job Title
                  </label>
                  <InputText
                    value={this.state.job_title}
                    onChange={(e) =>
                      this.setState({ job_title: e.target.value })
                    }
                    id='job_title'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='email' className='p-d-block'>
                    Email
                  </label>
                  <InputText
                    id='email'
                    type='email'
                    className='p-d-block w-100'
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='description' className='p-d-block'>
                    Short Description
                  </label>
                  <InputTextarea
                    id='description'
                    className='w-100'
                    rows={3}
                    autoResize
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='full_description' className='p-d-block'>
                    Full Description
                  </label>
                  <InputTextarea
                    id='full_description'
                    className='w-100'
                    rows={3}
                    autoResize
                    value={this.state.full_description}
                    onChange={(e) =>
                      this.setState({ full_description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label className='p-d-block'>Social Media</label>
                  <div className='p-grid'>
                    <div className='p-col-12 p-md-4'>
                      <InputText
                        id='toptal'
                        className='p-d-block w-100'
                        placeholder='Toptal'
                        value={this.state.social_media.toptal}
                        onChange={(e) =>
                          this.setState({
                            social_media: {
                              ...this.state.social_media,
                              toptal: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='p-col-12 p-md-4'>
                      <InputText
                        id='linkedin'
                        className='p-d-block w-100'
                        placeholder='Linkedin'
                        value={this.state.social_media.linkedin}
                        onChange={(e) =>
                          this.setState({
                            social_media: {
                              ...this.state.social_media,
                              linkedin: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='p-col-12 p-md-4'>
                      <InputText
                        id='facebook'
                        className='p-d-block w-100'
                        placeholder='Facebook'
                        value={this.state.social_media.facebook}
                        onChange={(e) =>
                          this.setState({
                            social_media: {
                              ...this.state.social_media,
                              facebook: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='p-col-12 p-md-4'>
                      <InputText
                        id='instagram'
                        className='p-d-block w-100'
                        placeholder='Instagram'
                        value={this.state.social_media.instagram}
                        onChange={(e) =>
                          this.setState({
                            social_media: {
                              ...this.state.social_media,
                              instagram: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='p-col-12 p-md-4'>
                      <InputText
                        id='twitter'
                        className='p-d-block w-100'
                        placeholder='Twitter'
                        value={this.state.social_media.twitter}
                        onChange={(e) =>
                          this.setState({
                            social_media: {
                              ...this.state.social_media,
                              twitter: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='map' className='p-d-block'>
                    Map Iframe Code
                  </label>
                  <InputTextarea
                    id='map'
                    className='w-100'
                    rows={3}
                    autoResize
                    value={this.state.map}
                    onChange={(e) => this.setState({ map: e.target.value })}
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='phone' className='p-d-block'>
                    Phone Number
                  </label>
                  <InputText
                    id='phone'
                    type='tel'
                    className='p-d-block w-100 p-mb-3'
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='site-title' className='p-d-block'>
                    Site Title
                  </label>
                  <InputText
                    id='site-title'
                    value={this.state.site_title}
                    onChange={(e) =>
                      this.setState({ site_title: e.target.value })
                    }
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='main-color' className='p-d-block'>
                    Site Main Color
                  </label>
                  <div className='p-d-flex'>
                    <ColorPicker
                      className='p-mr-2'
                      value={this.state.site_color}
                      onChange={(e) => this.setState({ site_color: e.value })}
                    />
                    <div className='p-inputgroup'>
                      <span className='p-inputgroup-addon'>#</span>
                      <InputText
                        id='main-color'
                        value={this.state.site_color}
                        onChange={(e) =>
                          this.setState({ site_color: e.target.value })
                        }
                        className='p-d-block w-100'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label className='p-d-block'>Logo</label>
                  <a
                    href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.logo}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.logo}`}
                      alt='Logo'
                      className='preview'
                    />
                  </a>
                  <Button
                    label='Select File'
                    className='p-button-outlined'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({ display: true, type: 'logo' })
                    }
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label className='p-d-block'>Profile Picture</label>
                  <a
                    href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.profile_picture}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.profile_picture}`}
                      alt='Profile'
                      className='preview'
                    />
                  </a>
                  <Button
                    label='Select File'
                    className='p-button-outlined'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({ display: true, type: 'pp' })
                    }
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label className='p-d-block'>CV</label>
                  {ext === 'pdf' ? (
                    <div className='p-d-block p-mb-3'>
                      <i className='pi pi-file-pdf p-mr-2' />
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.cv}`}
                      >
                        {cvName}
                      </a>
                    </div>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.cv}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.cv}`}
                        alt='CV'
                        className='preview'
                      />
                    </a>
                  )}
                  <Button
                    label='Select File'
                    className='p-button-outlined'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({ display: true, type: 'cv' })
                    }
                  />
                </div>
              </div>
            </div>
            <div className='p-grid p-mt-4'>
              <div className='p-col-12 p-md-12'>
                <Button
                  label='Save'
                  className='p-button w-100 main-color'
                  icon='pi pi-save'
                  iconPos='right'
                  loading={this.state.loading}
                  onClick={this.saveSettings}
                />
              </div>
            </div>
          </Card>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Info;
