import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Custom Components
import Files from '../../components/Files/Files';
import EmbeddedCode from '../../components/Embedded/Embedded';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

// Server API
import { addProject } from '../../api/portfolio';
import { getCategories } from '../../api/categories';
import { getTools } from '../../api/tools';
import { withRouter } from '../../helpers/routes/withRouter';
import placeholder from '../../assets/img/placeholder.jpg';

class AddProject extends Component {
  state = {
    // Loader
    loading: false,

    // Helper
    categories: [],
    getTools: [],

    // Dialog
    displayIframesDialog: false,
    displayThumbnailDialog: false,
    displayImagesDialog: false,
    type: '',

    // Inputs
    name: '',
    description: '',
    thumb: '',
    category: '',
    tools: [],
    links: [''],
    iframes: [],
    images: [],
  };

  getCategories = async () => {
    const categories = await getCategories();

    if (!categories.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Categories: ' + categories?.message,
      });
    }

    this.setState({ categories: categories.data });
  };

  getTools = async () => {
    const tools = await getTools();

    if (!tools.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Tools: ' + tools?.message,
      });
    }

    this.setState({ getTools: tools.data });
  };

  saveProject = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (
      !this.state.name ||
      !this.state.description ||
      !this.state.thumb ||
      !this.state.category ||
      !this.state.tools.length
    ) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });

      return this.setState({ loading: false });
    }

    const images = this.state.images.map((img) => img.id);
    const iframes = this.state.iframes.map((iframe) => iframe.id);

    const project = await addProject({
      name: this.state.name,
      thumbnail: this.state.thumb,
      content: this.state.description,
      portfolioCategoryID: this.state.category,
      links: JSON.stringify(this.state.links),
      videos: iframes,
      images: images,
      tools: this.state.tools,
    });

    this.toast.show({
      severity: project.success ? 'success' : 'error',
      summary: project.success ? 'Done' : 'Error',
      detail: project.success ? 'Project added successfully' : project.message,
    });

    if (project.success) {
      setTimeout(() => {
        _this.props.router.navigate('/portfolio');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  setFile = (type, path) => {
    this.setState({
      thumb: type === 'thumb' ? path : '',
    });
  };

  setIframes = (codes) => {
    this.setState({
      iframes: codes,
    });
  };

  setImages = (imgs) => {
    this.setState({
      images: imgs,
    });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Project`;
    await this.getCategories();
    await this.getTools();
  }

  render() {
    // Import Styling
    require('./Portfolio.scss');

    const links = this.state?.links?.map((link, i) => {
      return (
        <div className='p-grid p-ai-center p-mt-1' key={i}>
          <div className='p-col-11'>
            <InputText
              value={link}
              onChange={(e) => {
                const newLinks = [...this.state.links];
                newLinks[i] = e.target.value;
                this.setState({
                  links: newLinks,
                });
              }}
              placeholder='Enter a link'
              id={`link-${i}`}
              className='p-d-block w-100 p-mt-1'
            />
          </div>
          <div className='p-col-1'>
            <Button
              icon='pi pi-trash'
              className='p-button-rounded p-button-secondary'
              onClick={() => {
                const newLinks = [...this.state.links];
                newLinks.splice(i, 1);
                this.setState({
                  links: newLinks,
                });
              }}
            />
          </div>
        </div>
      );
    });

    const codes = this.state?.iframes?.map((code, i) => (
      <div className='item' key={i}>
        <span>{code.name}</span>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => {
            const newIframes = [...this.state.iframes];
            newIframes.splice(i, 1);
            this.setState({
              iframes: newIframes,
            });
          }}
        />
      </div>
    ));

    const images = this.state?.images?.map((img, i) => {
      const splitPath = img.path.split('/');
      const imgName = splitPath.pop();

      return (
        <div className='item' key={i}>
          <a
            href={process.env.REACT_APP_CONTENT_BASE_URL + img.path}
            target='_blank'
            rel='noreferrer'
          >
            {imgName}
          </a>
          <span>{img.name}</span>
          <Button
            icon='pi pi-trash'
            className='p-button-rounded p-button-secondary'
            onClick={() => {
              const newImages = [...this.state.images];
              newImages.splice(i, 1);
              this.setState({
                images: newImages,
              });
            }}
          />
        </div>
      );
    });

    const categories = this.state?.categories?.map((cat) => {
      return { label: cat.name, value: cat._id };
    });

    const tools = this.state?.getTools?.map((tool) => {
      return { label: tool.name, value: tool._id };
    });

    const footerThumbDialog = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ displayThumbnailDialog: false })}
          className='primary-color'
        />
      </div>
    );

    const footerVideosDialog = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ displayIframesDialog: false })}
          className='primary-color'
        />
      </div>
    );

    const footerImagesDialog = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ displayImagesDialog: false })}
          className='primary-color'
        />
      </div>
    );

    return (
      <div className='add-project'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />

        {/* Select Thumbnail */}
        <Dialog
          header='Select Thumbnail'
          maximizable
          visible={this.state.displayThumbnailDialog}
          style={{ width: '75vw' }}
          footer={footerThumbDialog}
          onHide={() => this.setState({ displayThumbnailDialog: false })}
          className='select-thumb-modal'
        >
          <Files
            popup={true}
            setFile={this.setFile}
            type={this.state.type}
            imagesOnly
          />
        </Dialog>

        {/* Select Videos */}
        <Dialog
          header='Select Iframe/s'
          maximizable
          visible={this.state.displayIframesDialog}
          style={{ width: '75vw' }}
          footer={footerVideosDialog}
          onHide={() => this.setState({ displayIframesDialog: false })}
          className='select-videos-modal'
        >
          <EmbeddedCode
            popup={true}
            iframes={this.state.iframes}
            setIframes={this.setIframes}
          />
        </Dialog>

        {/* Select Images */}
        <Dialog
          header='Select Image/s'
          maximizable
          visible={this.state.displayImagesDialog}
          style={{ width: '75vw' }}
          footer={footerImagesDialog}
          onHide={() => this.setState({ displayImagesDialog: false })}
          className='select-thumb-modal'
        >
          <Files
            popup={true}
            setImages={this.setImages}
            images={this.state.images}
            type={this.state.type}
            imagesOnly
          />
        </Dialog>

        <Header />
        <div className='container'>
          <Card title='Add Project' className='p-p-4 p-my-5'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-9'>
                <div className='p-grid'>
                  <div className='p-col-12 p-md-6'>
                    <div className='p-field'>
                      <label htmlFor='name' className='p-d-block'>
                        Name
                      </label>
                      <InputText
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        id='name'
                        className='p-d-block w-100'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-6'>
                    <div className='p-field'>
                      <label htmlFor='name' className='p-d-block'>
                        Category
                      </label>
                      <Dropdown
                        value={this.state.category}
                        options={categories}
                        onChange={(e) => this.setState({ category: e.value })}
                        placeholder='Select a Category'
                        className='p-column-filter w-100'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='name' className='p-d-block'>
                        Tools
                      </label>
                      <MultiSelect
                        value={this.state.tools}
                        options={tools}
                        onChange={(e) => this.setState({ tools: e.value })}
                        optionLabel='label'
                        className='w-100'
                        placeholder='Select Tools'
                        display='chip'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='description' className='p-d-block'>
                        Description
                      </label>
                      <Editor
                        style={{ height: '200px' }}
                        value={this.state.description}
                        onTextChange={(e) =>
                          this.setState({ description: e.htmlValue })
                        }
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='description' className='p-d-block'>
                        Links
                      </label>
                      {links}
                      <Button
                        label='Add Link'
                        icon='pi pi-plus'
                        iconPos='right'
                        className='w-100 primary-color p-mt-3'
                        onClick={() => {
                          const newLinks = this.state.links;
                          newLinks.push('');
                          this.setState({ links: newLinks });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-12 p-md-3'>
                <div className='p-field'>
                  <label className='p-d-block'>Thumbnail</label>
                  {this.state.thumb ? (
                    <a
                      href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.thumb}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.thumb}`}
                        alt='Icon'
                        className='preview'
                      />
                    </a>
                  ) : (
                    <img src={placeholder} alt='Icon' className='preview' />
                  )}

                  <Button
                    label='Select Thumbnail'
                    className='p-button-outlined w-100'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({
                        displayThumbnailDialog: true,
                        type: 'thumb',
                      })
                    }
                  />
                </div>

                <div className='p-field'>
                  <div className='items'>{codes}</div>
                  <Button
                    label='Select Iframe/s'
                    className='p-button-outlined w-100'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({ displayIframesDialog: true })
                    }
                  />
                </div>

                <div className='p-field'>
                  <div className='items'>{images}</div>
                  <Button
                    label='Select Image/s'
                    className='p-button-outlined w-100'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({
                        displayImagesDialog: true,
                        type: 'images',
                      })
                    }
                  />
                </div>

                <div className='p-field'>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveProject}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddProject);
