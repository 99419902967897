import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';
import { withRouter } from '../../helpers/routes/withRouter';
// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

// Server API
import { addAdmin } from '../../api/admins';

class AddAdmin extends Component {
  state = {
    // Loader
    loading: false,

    // Inputs
    fullname: '',
    username: '',
    password: '',
  };

  saveAdmin = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (!this.state.fullname || !this.state.username || !this.state.password) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const admin = await addAdmin({
      fullname: this.state.fullname,
      username: this.state.username,
      password: this.state.password,
    });

    this.toast.show({
      severity: admin.success ? 'success' : 'error',
      summary: admin.success ? 'Done' : 'Error',
      detail: admin.success ? 'Admin added successfully' : admin.message,
    });

    if (admin.success) {
      setTimeout(() => {
        _this.props.router.navigate('/admins');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Admin`;
  }

  render() {
    // Import Styling
    require('./Admins.scss');

    return (
      <div className='add-admin'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Add Admin' className='p-p-4 p-my-5'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='fullname' className='p-d-block'>
                    Full Name
                  </label>
                  <InputText
                    value={this.state.fullname}
                    onChange={(e) =>
                      this.setState({ fullname: e.target.value })
                    }
                    id='fullname'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='username' className='p-d-block'>
                    Username
                  </label>
                  <InputText
                    value={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    id='username'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='password' className='p-d-block'>
                    Password
                  </label>
                  <Password
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    id='password'
                    className='p-d-block'
                    toggleMask
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveAdmin}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddAdmin);
