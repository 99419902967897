import { Navigate, Outlet } from 'react-router-dom';
import cookies from 'js-cookie';

const ProtectedRoute = (props) => {
  const token = cookies.get('ankit_token');

  return token ? <Outlet /> : <Navigate to='/' />;
};

export default ProtectedRoute;
