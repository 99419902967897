import server from "./server";

const getIframes = async () => {
  try {
    const iframes = await server.get("/videos");
    return iframes.data;
  } catch (error) {
    return error.response.data;
  }
};

const getIframe = async (id) => {
  try {
    const iframe = await server.get(`/videos/${id}`);
    return iframe.data;
  } catch (error) {
    return error.response.data;
  }
};

const addIframe = async (data) => {
  try {
    const iframe = await server.post("/videos", data);
    return iframe.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateIframe = async (id, data) => {
  try {
    const iframe = await server.put(`/videos/${id}`, data);
    return iframe.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteIframe = async (id) => {
  try {
    const removeIframe = await server.delete(`/videos/${id}`);
    return removeIframe.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getIframes, getIframe, addIframe, updateIframe, deleteIframe };
