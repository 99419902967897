import server from "./server";

const getExperiences = async () => {
  try {
    const experiences = await server.get("/experiences");
    return experiences.data;
  } catch (error) {
    return error.response.data;
  }
};

const getExperience = async (id) => {
  try {
    const experience = await server.get(`/experiences/${id}`);
    return experience.data;
  } catch (error) {
    return error.response.data;
  }
};

const addExperience = async (data) => {
  try {
    const experience = await server.post("/experiences", data);
    return experience.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateExperience = async (id, data) => {
  try {
    const experience = await server.put(`/experiences/${id}`, data);
    return experience.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteExperience = async (id) => {
  try {
    const removeExperience = await server.delete(`/experiences/${id}`);
    return removeExperience.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getExperiences,
  getExperience,
  addExperience,
  updateExperience,
  deleteExperience,
};
