import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

// Import Styling
import './index.scss';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Import Main Connector
import Connector from './Connector';

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Connector />
  </React.StrictMode>
);
