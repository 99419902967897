import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

// Server API
import { getTools, deleteTool } from '../../api/tools';
import { withRouter } from '../../helpers/routes/withRouter';

class Tools extends Component {
  state = {
    data: [],
    globalFilter: '',
  };

  getTools = async () => {
    const tools = await getTools();

    if (!tools.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: tools?.response?.data?.message,
      });
    }

    this.setState({ data: tools.data });
  };

  deleteTools = async (id) => {
    const removeTool = await deleteTool(id);

    this.toast.show({
      severity: removeTool.success ? 'success' : 'error',
      summary: removeTool.success ? 'Done' : 'Error',
      detail: removeTool.message,
    });

    await this.getTools();
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Tools`;
    await this.getTools();
  }

  render() {
    // Import Styling
    require('./Tools.scss');

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this code?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteTools(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Tools
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const actionBodyTemplate = (rowData) => (
      <>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />

        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-primary p-ml-2 primary-color'
          onClick={() =>
            this.props.router.navigate(`/tools/edit/${rowData._id}`)
          }
        />
      </>
    );

    return (
      <div className='tools'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Tools' className='p-p-4 p-my-5'>
            <Button
              label='Add New'
              icon='pi pi-plus'
              className='primary-color'
              onClick={() => this.props.router.navigate('/tools/add')}
            />

            <div className='datatable-responsive-demo'>
              <DataTable
                paginator
                rows={15}
                rowsPerPageOptions={[15, 30, 60]}
                className='p-mt-3 p-datatable-responsive-demo'
                emptyMessage='No tools found.'
                value={this.state.data}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column field='name' header='Tool' sortable filter />
                <Column header='Actions' body={actionBodyTemplate} />
              </DataTable>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Tools);
