import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Server API
import { getTool, updateTool } from '../../api/tools';
import { withRouter } from '../../helpers/routes/withRouter';

class EditTool extends Component {
  state = {
    // ID
    id: this.props.router.params.id,

    // Loader
    loading: false,

    // Inputs
    tool: '',
  };

  getTool = async () => {
    const tool = await getTool(this.state.id);

    if (!tool.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: tool?.message,
      });
    }

    this.setState({
      tool: tool.data.name,
    });
  };

  saveTool = async () => {
    this.setState({ loading: true });

    if (!this.state.tool) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const tool = await updateTool(this.state.id, {
      name: this.state.tool,
    });

    this.toast.show({
      severity: tool.success ? 'success' : 'error',
      summary: tool.success ? 'Done' : 'Error',
      detail: tool.success ? 'Tool updated successfully' : tool.message,
    });

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Edit Tool`;
    await this.getTool();
  }

  render() {
    // Import Styling
    require('./Tools.scss');

    return (
      <div className='add-tool'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Update Tool' className='p-p-4 p-my-5'>
            <div className='p-grid p-jc-center'>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='tool' className='p-d-block'>
                    Tool
                  </label>
                  <InputText
                    value={this.state.tool}
                    onChange={(e) => this.setState({ tool: e.target.value })}
                    id='tool'
                    className='p-d-block w-100'
                    rows={5}
                    autoResize
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='percentage' className='p-d-block'>
                    &nbsp;
                  </label>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveTool}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(EditTool);
