import server from "./server";

const getServices = async () => {
  try {
    const services = await server.get("/services");
    return services.data;
  } catch (error) {
    return error.response.data;
  }
};

const getService = async (id) => {
  try {
    const service = await server.get(`/services/${id}`);
    return service.data;
  } catch (error) {
    return error.response.data;
  }
};

const addService = async (data) => {
  try {
    const service = await server.post("/services", data);
    return service.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateService = async (id, data) => {
  try {
    const service = await server.put(`/services/${id}`, data);
    return service.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteService = async (id) => {
  try {
    const removeService = await server.delete(`/services/${id}`);
    return removeService.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getServices, getService, addService, updateService, deleteService };
