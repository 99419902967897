import server from "./server";

const getTools = async () => {
  try {
    const tools = await server.get("/tools");
    return tools.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTool = async (id) => {
  try {
    const tool = await server.get(`/tools/${id}`);
    return tool.data;
  } catch (error) {
    return error.response.data;
  }
};

const addTool = async (data) => {
  try {
    const tool = await server.post("/tools", data);
    return tool.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTool = async (id, data) => {
  try {
    const tool = await server.put(`/tools/${id}`, data);
    return tool.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTool = async (id) => {
  try {
    const removeTool = await server.delete(`/tools/${id}`);
    return removeTool.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getTools, getTool, addTool, updateTool, deleteTool };
