import server from "./server";

const getFiles = async () => {
  try {
    const files = await server.get("/files");
    return files.data;
  } catch (error) {
    return error.response.data;
  }
};

const uploadFile = async (data) => {
  try {
    const file = await server.post("/files", data);
    return file.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteFile = async (id) => {
  try {
    const removeFile = await server.delete(`/files/${id}`);
    return removeFile.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getFiles, uploadFile, deleteFile };
