import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

// Server API
import { getExperience, updateExperience } from '../../api/work-experiences';
import { withRouter } from '../../helpers/routes/withRouter';
import { Dropdown } from 'primereact/dropdown';

class EditExperience extends Component {
  state = {
    // ID
    id: this.props.router.params.id,

    // Loader
    loading: false,

    // Inputs
    title: '',
    company: '',
    description: '',
    position: '',
    location: '',
    from: '',
    to: '',
  };

  getExperience = async () => {
    const experience = await getExperience(this.state.id);

    if (!experience.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: experience?.message,
      });
    }

    this.setState({
      title: experience.data.title,
      company: experience.data.company,
      description: experience.data.description,
      position: experience.data.position,
      location: experience.data.location,
      from: new Date(experience.data.from),
      to: experience.data.to ? new Date(experience.data.to) : null,
    });
  };

  saveExperience = async () => {
    this.setState({ loading: true });

    if (
      !this.state.title ||
      !this.state.company ||
      !this.state.description ||
      !this.state.position ||
      !this.state.location ||
      !this.state.from
    ) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const experience = await updateExperience(this.state.id, {
      title: this.state.title,
      company: this.state.company,
      description: this.state.description,
      position: this.state.position,
      location: this.state.location,
      from: this.state.from,
      to: this.state.to,
    });

    this.toast.show({
      severity: experience.success ? 'success' : 'error',
      summary: experience.success ? 'Done' : 'Error',
      detail: experience.success
        ? 'Experience updated successfully'
        : experience.message,
    });

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Edit Experience`;
    await this.getExperience();
  }

  render() {
    // Import Styling
    require('./Experiences.scss');
    let positions = [
      { label: 'Full-time', value: 'Full-time' },
      { label: 'Part-time', value: 'Part-time' },
      { label: 'Self-Employed', value: 'Self-Employed' },
      { label: 'Freelance', value: 'Freelance' },
      { label: 'Internship', value: 'Internship' },
    ];
    return (
      <div className='add-experience'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Update Experience' className='p-p-4 p-my-5'>
            <div className='p-grid'>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='title' className='p-d-block'>
                    Title
                  </label>
                  <InputText
                    value={this.state.title}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    id='title'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='company' className='p-d-block'>
                    Company
                  </label>
                  <InputText
                    value={this.state.company}
                    onChange={(e) => this.setState({ company: e.target.value })}
                    id='company'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='position' className='p-d-block'>
                    Position
                  </label>
                  <Dropdown
                    value={this.state.position}
                    options={positions}
                    onChange={(e) => this.setState({ position: e.value })}
                    placeholder='Select a Position'
                    className='p-column-filter w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='location' className='p-d-block'>
                    Location
                  </label>
                  <InputText
                    value={this.state.location}
                    onChange={(e) =>
                      this.setState({ location: e.target.value })
                    }
                    id='location'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='description' className='p-d-block'>
                    Description
                  </label>
                  <InputTextarea
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    rows={3}
                    className='w-100'
                    autoResize
                    id='description'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='from' className='p-d-block'>
                    From
                  </label>
                  <Calendar
                    id='from'
                    dateFormat='yy-mm-dd'
                    value={this.state.from}
                    onChange={(e) => this.setState({ from: e.value })}
                    className='w-100'
                    showIcon
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='from' className='p-d-block'>
                    To
                  </label>
                  <Calendar
                    id='from'
                    dateFormat='yy-mm-dd'
                    value={this.state.to}
                    onChange={(e) => this.setState({ to: e.value })}
                    className='w-100'
                    showIcon
                  />
                  <small className='p-d-block'>
                    Leave it empty if you are currently work there.
                  </small>
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveExperience}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(EditExperience);
