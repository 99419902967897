import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Helpers
import ProtectedRoute from './helpers/routes/ProtectedRoute';
import GuestRoute from './helpers/routes/GuestRoute';

// Routes
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Info from './pages/Info/Info';
import Media from './pages/Media/Media';

import Admins from './pages/Admins/Admins';
import AddAdmin from './pages/Admins/AddAdmin';
import EditAdmin from './pages/Admins/EditAdmin';

import Emails from './pages/Emails/Emails';
import ViewEmail from './pages/Emails/ViewEmail';

import Services from './pages/Services/Services';
import AddService from './pages/Services/AddService';
import EditService from './pages/Services/EditService';

import Testimonials from './pages/Testimonials/Testimonials';
import AddTestimonial from './pages/Testimonials/AddTestimonial';
import EditTestimonial from './pages/Testimonials/EditTestimonial';

import Experiences from './pages/WorkExperiences/Experiences';
import AddExperience from './pages/WorkExperiences/AddExperience';
import EditExperience from './pages/WorkExperiences/EditExperience';

import Skills from './pages/Skills/Skills';
import AddSkill from './pages/Skills/AddSkill';
import EditSkill from './pages/Skills/EditSkill';

import Embedded from './pages/Embedded/Embedded';
import AddCategory from './pages/Categories/AddCategory';
import EditCategory from './pages/Categories/EditCategory';

import Categories from './pages/Categories/Categories';
import AddCode from './pages/Embedded/AddCode';
import EditCode from './pages/Embedded/EditCode';

import Tools from './pages/Tools/Tools';
import AddTool from './pages/Tools/AddTool';
import EditTool from './pages/Tools/EditTool';

import Portfolio from './pages/Portfolio/Portfolio';
import AddProject from './pages/Portfolio/AddProject';
import EditProject from './pages/Portfolio/EditProject';

import Blog from './pages/Blog/Blog';
import AddArticle from './pages/Blog/AddArticle';
import EditArticle from './pages/Blog/EditArticle';

// 404
import NotFound from './pages/404/404';
import { ConfirmDialog } from 'primereact/confirmdialog';

function App() {
  return (
    <div className='connector'>
      <BrowserRouter basename='/'>
        <ConfirmDialog />
        <Routes>
          {/* Login */}
          <Route element={<GuestRoute />}>
            <Route path='/' element={<Login />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            {/* Home */}
            <Route path='/home' element={<Home />} />
            {/* General Info */}
            <Route path='/info' element={<Info />} />
            {/* Media */}
            <Route path='/media' element={<Media />} />
            {/* Emails */}
            <Route path='/emails' element={<Emails />} />
            <Route path='/emails/view/:id' element={<ViewEmail />} />
            {/* Admin */}
            <Route path='/admins' element={<Admins />} />
            <Route path='/admins/add' element={<AddAdmin />} />
            <Route path='/admins/edit/:id' element={<EditAdmin />} />{' '}
            {/* Services */}
            <Route path='/services' element={<Services />} />
            <Route path='/services/add' element={<AddService />} />
            <Route path='/services/edit/:id' element={<EditService />} />
            {/* Testimonials */}
            <Route path='/testimonials' element={<Testimonials />} />
            <Route path='/testimonials/add' element={<AddTestimonial />} />
            <Route
              path='/testimonials/edit/:id'
              element={<EditTestimonial />}
            />
            {/* Work Experiences */}
            <Route path='/experiences' element={<Experiences />} />
            <Route path='/experiences/add' element={<AddExperience />} />
            <Route path='/experiences/edit/:id' element={<EditExperience />} />
            {/* Skills */}
            <Route path='/skills' element={<Skills />} />
            <Route path='/skills/add' element={<AddSkill />} />
            <Route path='/skills/edit/:id' element={<EditSkill />} />
            {/* Embedded Codes */}
            <Route path='/codes' element={<Embedded />} />
            <Route path='/codes/add' element={<AddCode />} />
            <Route path='/codes/edit/:id' element={<EditCode />} />
            {/* Categories */}
            <Route path='/categories' element={<Categories />} />
            <Route path='/categories/add' element={<AddCategory />} />
            <Route path='/categories/edit/:id' element={<EditCategory />} />
            {/* Tools */}
            <Route path='/tools' element={<Tools />} />
            <Route path='/tools/add' element={<AddTool />} />
            <Route path='/tools/edit/:id' element={<EditTool />} />
            {/* Portfolio */}
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/portfolio/add' element={<AddProject />} />
            <Route path='/portfolio/edit/:id' element={<EditProject />} />
            {/* Blog */}
            <Route path='/blog' element={<Blog />} />
            <Route path='/blog/add' element={<AddArticle />} />
            <Route path='/blog/edit/:id' element={<EditArticle />} />
          </Route>

          {/* 404 */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
