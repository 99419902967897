import server from "./server";

const getTestimonials = async () => {
  try {
    const testimonials = await server.get("/testimonials");
    return testimonials.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTestimonial = async (id) => {
  try {
    const testimonial = await server.get(`/testimonials/${id}`);
    return testimonial.data;
  } catch (error) {
    return error.response.data;
  }
};

const addTestimonial = async (data) => {
  try {
    const testimonial = await server.post("/testimonials", data);
    return testimonial.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTestimonial = async (id, data) => {
  try {
    const testimonial = await server.put(`/testimonials/${id}`, data);
    return testimonial.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTestimonial = async (id) => {
  try {
    const removeTestimonial = await server.delete(`/testimonials/${id}`);
    return removeTestimonial.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getTestimonials,
  getTestimonial,
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
};
