import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Custom Components
import Files from '../../components/Files/Files';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

// Server API
import { addService } from '../../api/services';
import { withRouter } from '../../helpers/routes/withRouter';
import placeholder from '../../assets/img/placeholder.jpg';

class AddService extends Component {
  state = {
    // Loader
    loading: false,

    // Dialog
    display: false,
    type: '',

    // Inputs
    name: '',
    description: '',
    icon: '',
  };

  saveService = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (!this.state.name || !this.state.description || !this.state.icon) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });

      return this.setState({ loading: false });
    }

    const service = await addService({
      name: this.state.name,
      description: this.state.description,
      icon: this.state.icon,
    });

    this.toast.show({
      severity: service.success ? 'success' : 'error',
      summary: service.success ? 'Done' : 'Error',
      detail: service.success ? 'Service added successfully' : service.message,
    });

    if (service.success) {
      setTimeout(() => {
        _this.props.router.navigate('/services');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  setFile = (type, path) => {
    this.setState({
      icon: type === 'icon' ? path : '',
    });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Service`;
  }

  render() {
    // Import Styling
    require('./Services.scss');

    const footer = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ display: false })}
          className='primary-color'
        />
      </div>
    );

    return (
      <div className='add-service'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Dialog
          header='Select File'
          maximizable
          visible={this.state.display}
          style={{ width: '75vw' }}
          footer={footer}
          onHide={() => this.setState({ display: false })}
          className='upload-file-modal'
        >
          <Files popup={true} setFile={this.setFile} type={this.state.type} />
        </Dialog>
        <Header />
        <div className='container'>
          <Card title='Add Service' className='p-p-4 p-my-5'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-10'>
                <div className='p-grid'>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='name' className='p-d-block'>
                        Name
                      </label>
                      <InputText
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        id='name'
                        className='p-d-block w-100'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='description' className='p-d-block'>
                        Description
                      </label>
                      <InputTextarea
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        rows={3}
                        className='w-100'
                        autoResize
                        id='description'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-4'>
                    <div className='p-field'>
                      <Button
                        label='Save'
                        icon='pi pi-save'
                        iconPos='right'
                        className='w-100 primary-color'
                        loading={this.state.loading}
                        onClick={this.saveService}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-12 p-md-2'>
                <div className='p-field'>
                  <label className='p-d-block'>Icon</label>
                  {this.state.icon ? (
                    <a
                      href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.icon}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.icon}`}
                        alt='Icon'
                        className='preview'
                      />
                    </a>
                  ) : (
                    <img src={placeholder} alt='Icon' className='preview' />
                  )}

                  <Button
                    label='Select File'
                    className='p-button-outlined w-100'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({ display: true, type: 'icon' })
                    }
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddService);
