import { Component } from 'react';
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';

// Assets
import Logo from '../../../assets/img/Logo.png';

// Components
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { withRouter } from '../../../helpers/routes/withRouter';

class Header extends Component {
  state = {
    loading: false,
  };

  logout = () => {
    const _this = this;
    this.setState({ loading: true });
    cookies.remove('ankit_token');
    setTimeout(() => {
      _this.props.router.navigate('/');
    }, 1000);
  };

  render() {
    // Import Styling
    require('./Header.scss');

    const items = [
      {
        label: 'Portfolio',
        icon: 'pi pi-fw pi-folder',
        items: [
          {
            label: 'New Project',
            icon: 'pi pi-fw pi-plus',
            command: () => {
              console.log(this.props);
              this.props.router.navigate('/portfolio/add');
            },
          },
          {
            label: 'All Projects',
            icon: 'pi pi-fw pi-briefcase',
            command: () => {
              this.props.router.navigate('/portfolio');
            },
          },
          {
            separator: true,
          },
          {
            label: 'Categories',
            icon: 'pi pi-fw pi-folder',
            items: [
              {
                label: 'New Category',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/categories/add');
                },
              },
              {
                label: 'All Categories',
                icon: 'pi pi-fw pi-folder',
                command: () => {
                  this.props.router.navigate('/categories');
                },
              },
            ],
          },
          {
            label: 'Tools',
            icon: 'pi pi-fw pi-folder',
            items: [
              {
                label: 'New Tool',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/tools/add');
                },
              },
              {
                label: 'All Tools',
                icon: 'pi pi-fw pi-folder',
                command: () => {
                  this.props.router.navigate('/tools');
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Blog',
        icon: 'pi pi-fw pi-book',
        items: [
          {
            label: 'New Article',
            icon: 'pi pi-fw pi-plus',
            command: () => {
              this.props.router.navigate('/blog/add');
            },
          },
          {
            separator: true,
          },
          {
            label: 'All Articles',
            icon: 'pi pi-fw pi-book',
            command: () => {
              this.props.router.navigate('/blog');
            },
          },
        ],
      },
      {
        label: 'Media',
        icon: 'pi pi-fw pi-images',
        items: [
          {
            label: 'All Files',
            icon: 'pi pi-fw pi-images',
            command: () => {
              this.props.router.navigate('/media');
            },
          },
          {
            separator: true,
          },
          {
            label: 'Embedded',
            icon: 'pi pi-fw pi-video',
            items: [
              {
                label: 'New',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/codes/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Embedded',
                icon: 'pi pi-fw pi-video',
                command: () => {
                  this.props.router.navigate('/codes');
                },
              },
            ],
          },
        ],
      },
      {
        label: 'About Info',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Services',
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: 'New Service',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/services/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Services',
                icon: 'pi pi-fw pi-file',
                command: () => {
                  this.props.router.navigate('/services');
                },
              },
            ],
          },
          {
            label: 'Work Experiences',
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: 'New Experiences',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/experiences/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Experiences',
                icon: 'pi pi-fw pi-file',
                command: () => {
                  this.props.router.navigate('/experiences');
                },
              },
            ],
          },
          {
            label: 'Testimonials',
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: 'New Testimonial',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/testimonials/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Testimonials',
                icon: 'pi pi-fw pi-file',
                command: () => {
                  this.props.router.navigate('/testimonials');
                },
              },
            ],
          },
          {
            label: 'Skills',
            icon: 'pi pi-fw pi-file',
            items: [
              {
                label: 'New Skill',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/skills/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Skills',
                icon: 'pi pi-fw pi-file',
                command: () => {
                  this.props.router.navigate('/skills');
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Info',
        icon: 'pi pi-fw pi-info-circle',
        items: [
          {
            label: 'General Info',
            icon: 'pi pi-fw pi-info-circle',
            command: () => {
              this.props.router.navigate('/info');
            },
          },
          {
            label: 'Admins',
            icon: 'pi pi-fw pi-users',
            items: [
              {
                label: 'New Admin',
                icon: 'pi pi-fw pi-plus',
                command: () => {
                  this.props.router.navigate('/admins/add');
                },
              },
              {
                separator: true,
              },
              {
                label: 'All Admins',
                icon: 'pi pi-fw pi-users',
                command: () => {
                  this.props.router.navigate('/admins');
                },
              },
            ],
          },
          {
            label: 'Emails',
            icon: 'pi pi-fw pi-envelope',
            command: () => {
              this.props.router.navigate('/emails');
            },
          },
        ],
      },
    ];

    const start = (
      <Link to='/home'>
        <img src={Logo} width='150' alt={process.env.REACT_APP_NAME} />
      </Link>
    );
    const end = (
      <Button
        label='Logout'
        iconPos='right'
        icon='pi pi-power-off'
        loading={this.state.loading}
        onClick={this.logout}
      />
    );

    return (
      <header>
        <div className='container'>
          <Menubar model={items} start={start} end={end} />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
