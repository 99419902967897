import server from "./server";

const getCategories = async () => {
  try {
    const categories = await server.get("/categories");
    return categories.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCategory = async (id) => {
  try {
    const category = await server.get(`/categories/${id}`);
    return category.data;
  } catch (error) {
    return error.response.data;
  }
};

const addCategory = async (data) => {
  try {
    const category = await server.post("/categories", data);
    return category.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateCategory = async (id, data) => {
  try {
    const category = await server.put(`/categories/${id}`, data);
    return category.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteCategory = async (id) => {
  try {
    const removeCategory = await server.delete(`/categories/${id}`);
    return removeCategory.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getCategories,
  getCategory,
  addCategory,
  updateCategory,
  deleteCategory,
};
