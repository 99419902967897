import { Component } from 'react';
import cookies from 'js-cookie';

// Components
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';

// Server API
import { getFiles, deleteFile } from '../../api/files';

class Files extends Component {
  state = {
    data: [],
    caption: '',
    globalFilter: '',
    selectedFile: '',
    selectedFiles: this.props.images,
  };

  onUpload = async (e) => {
    this.toast.show({
      severity: 'success',
      summary: 'Done',
      detail: 'File Uploaded Successfully',
    });
    this.setState({ caption: '' });

    await this.allMedia();
  };

  onError = (e) => {
    console.log(e);
    const errorMessage = JSON.parse(e?.xhr?.response);
    this.toast.show({
      severity: 'error',
      summary: "Can't Upload The File",
      detail: errorMessage.message,
    });
  };

  sendDataAndToken = (e) => {
    const token = cookies.get('ankit_token');
    e.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    e.formData.append('caption', this.state.caption);
  };

  allMedia = async () => {
    const files = await getFiles();

    if (!files.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: files?.message,
      });
    }

    let data = files.data;

    // Remove PDFs From Files
    if (this.props.imagesOnly) {
      data = data.filter((file) => {
        const splitPath = file.path.split('/');
        const getFileName = splitPath.pop();
        const getExtension = getFileName.split('.');
        const ext = getExtension.pop();

        if (ext !== 'pdf') {
          return file;
        } else {
          return null;
        }
      });
    }

    this.setState({ data });
  };

  deleteFile = async (id) => {
    const removeFile = await deleteFile(id);

    this.toast.show({
      severity: removeFile.success ? 'success' : 'error',
      summary: removeFile.success ? 'Done' : 'Error',
      detail: removeFile.message,
    });

    await this.allMedia();
  };

  async componentDidMount() {
    await this.allMedia();
  }

  render() {
    // Import Styling
    require('./Files.scss');

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this file?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteFile(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Media
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const formatDate = (rawData) => {
      return new Date(rawData.createdAt).toLocaleString();
    };

    const imageBodyTemplate = (rowData) => {
      const path = rowData.path.split('/');
      const name = path.pop();
      const ext = name.split('.').pop();

      if (ext === 'pdf') {
        return (
          <div style={{ wordBreak: 'break-all' }}>
            <i className='pi pi-file-pdf p-mr-2' />
            <a
              href={`${process.env.REACT_APP_CONTENT_BASE_URL}${rowData?.path}`}
              target='_blank'
              rel='noreferrer'
            >
              {name}
            </a>
          </div>
        );
      }

      return (
        <img
          src={`${process.env.REACT_APP_CONTENT_BASE_URL}${rowData?.path}`}
          alt={rowData?.path}
          className='thumb-image'
        />
      );
    };

    const actionBodyTemplate = (rowData) => {
      return (
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />
      );
    };

    const selectFile = (rowData) => {
      return (
        <RadioButton
          value={rowData.path}
          name='selectedFile'
          onChange={(e) => {
            this.setState({ selectedFile: e.value });
            this.props.setFile(this.props.type, e.value);
          }}
          checked={this.state.selectedFile === rowData.path}
        />
      );
    };

    const selectFiles = (rowData) => {
      return (
        <Checkbox
          inputId={`file-${rowData._id}`}
          value={{ path: rowData.path, id: rowData._id }}
          onChange={(e) => {
            let selectedFiles = [...this.state.selectedFiles];

            if (!e.checked) {
              selectedFiles.splice(
                this.state.selectedFiles.findIndex(
                  (val) => val.id === rowData._id
                ),
                1
              );
              this.setState({ selectedFiles: selectedFiles });
              this.props.setImages(selectedFiles);
              return;
            }

            selectedFiles.push(e.value);
            this.setState({ selectedFiles: selectedFiles });
            this.props.setImages(selectedFiles);
          }}
          checked={this.state.selectedFiles.some(
            (val) => val.id === rowData._id
          )}
        />
      );
    };

    return (
      <div className='upload-file'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <div className='p-field'>
          <label htmlFor='Fullname' className='p-d-block'>
            Caption
          </label>
          <InputText
            id='Fullname'
            value={this.state.caption}
            onChange={(e) => this.setState({ caption: e.target.value })}
            className='p-d-block w-100'
          />
        </div>

        <FileUpload
          name='file'
          url={`${process.env.REACT_APP_API_URL}/files`}
          onUpload={this.onUpload}
          onBeforeSend={this.sendDataAndToken}
          accept='.jpg, .jpeg, .png, .gif, .pdf'
          maxFileSize={5000000}
          onError={this.onError}
          emptyTemplate={
            <p className='p-m-0'>Drag and drop files here to upload.</p>
          }
        />

        <div className='datatable-responsive-demo'>
          <DataTable
            paginator
            rows={15}
            rowsPerPageOptions={[15, 30, 60]}
            className='p-mt-5 p-datatable-responsive-demo'
            emptyMessage='No files found.'
            value={this.state.data}
            header={header}
            globalFilter={this.state.globalFilter}
            showGridlines
          >
            <Column
              body={imageBodyTemplate}
              field='path'
              header='Preview'
              sortable
              filter
            />

            <Column
              body={(row) => {
                return (
                  <a href={process.env.REACT_APP_CONTENT_BASE_URL + row.path}>
                    Open File
                  </a>
                );
              }}
              header='Open File'
            />

            <Column
              body={formatDate}
              field='createdAt'
              header='Date'
              sortable
              filter
            />
            <Column header='Actions' body={actionBodyTemplate} />
            {this.props.popup && this.props.type === 'images' ? (
              <Column header='Select File' body={selectFiles} />
            ) : this.props.popup && this.props.type !== 'images' ? (
              <Column header='Select File' body={selectFile} />
            ) : null}
          </DataTable>
        </div>
      </div>
    );
  }
}

export default Files;
