import { Component } from 'react';
import cookies from 'js-cookie';

// Assets
import Logo from '../../assets/img/Logo.png';

// Components
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

// Server API
import { login as LoginAPI } from '../../api/login';
import { withRouter } from '../../helpers/routes/withRouter';

class Login extends Component {
  state = {
    username: '',
    password: '',
    loading: false,
  };

  login = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    if (!this.state.username || !this.state.password) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please enter username and password',
        life: 3000,
      });

      return this.setState({ loading: false });
    }

    const login = await LoginAPI({
      username: this.state.username,
      password: this.state.password,
    });

    this.toast.show({
      severity: login?.success ? 'success' : 'error',
      summary: login?.success ? 'Logged in successfully' : 'Error',
      detail: login?.success ? `Welcome ${login?.fullname}` : login?.message,
      life: 1500,
    });

    if (login?.success) {
      cookies.set('ankit_token', login.token);
      setTimeout(() => {
        if (window.location.href.substr(-1) === '/') {
          window.location.href += 'home';
        } else {
          window.location.href += '/home';
        }
      }, 1500);
    }

    this.setState({ loading: false });
  };

  componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Login`;
  }

  render() {
    // Import Styling
    require('./Login.scss');

    return (
      <div className='login'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <div className='container'>
          <div className='full-height p-grid p-justify-center p-align-center'>
            <div className='p-col-12 p-md-6'>
              <Card>
                <div className='logo-wrapper'>
                  <img
                    className='logo'
                    src={Logo}
                    alt={process.env.REACT_APP_NAME}
                  />
                  <div className='title'>Dashboard</div>
                </div>
                <form onSubmit={this.login}>
                  <span className='p-float-label p-mb-4'>
                    <InputText
                      id='username'
                      value={this.state.username}
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                    />
                    <label htmlFor='username'>Username</label>
                  </span>
                  <span className='p-float-label p-mb-4'>
                    <Password
                      id='password'
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      toggleMask
                      feedback={false}
                    />
                    <label htmlFor='password'>Password</label>
                  </span>
                  <Button
                    label='Login'
                    icon='pi pi-arrow-right'
                    iconPos='right'
                    loading={this.state.loading}
                  />
                </form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
