import axios from 'axios';
import cookies from 'js-cookie';

const token = cookies.get('ankit_token');

const server = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export default server;
