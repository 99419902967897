import { Component } from "react";

// Layout Components
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";

// Components
import { Card } from "primereact/card";

// Custom Components
import Files from "../../components/Files/Files";

class Media extends Component {

  componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Media`
  }

  render() {
    // Import Styling
    require("./Media.scss");

    return (
      <div className="media">
        <Header />
        <div className="container">
          <Card title="Media" className="p-p-4 p-my-5">
            <Files />
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Media;
