import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

// Server API
import { getEmails, deleteEmail } from '../../api/emails';
import { withRouter } from '../../helpers/routes/withRouter';

class Emails extends Component {
  state = {
    data: [],
  };

  getEmails = async () => {
    const emails = await getEmails();

    if (!emails.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: emails?.message,
      });
    }

    this.setState({ data: emails.data });
  };

  deleteEmail = async (id) => {
    const removeEmail = await deleteEmail(id);

    this.toast.show({
      severity: removeEmail.success ? 'success' : 'error',
      summary: removeEmail.success ? 'Done' : 'Error',
      detail: removeEmail.message,
    });

    await this.getEmails();
  };

  async componentDidMount() {
    await this.getEmails();
  }

  render() {
    // Import Styling
    require('./Emails.scss');

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this email?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteEmail(id),
        reject: () => console.log('No'),
      });
    };

    const formatBody = (rawData) => {
      return rawData.text.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 20);
    };

    const formatDate = (rawData) => {
      return new Date(rawData.createdAt).toLocaleString();
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Emails
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const actionBodyTemplate = (rowData) => (
      <>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />

        <Button
          icon='pi pi-eye'
          className='p-button-rounded p-button-primary p-ml-2 primary-color'
          onClick={() =>
            this.props.router.navigate(`/emails/view/${rowData._id}`)
          }
        />
      </>
    );

    return (
      <div className='emails'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Emails' className='p-p-4 p-my-5'>
            <div className='datatable-responsive-demo'>
              <DataTable
                paginator
                rows={15}
                rowsPerPageOptions={[15, 30, 60]}
                className='p-mt-5 p-datatable-responsive-demo'
                emptyMessage='No emails found.'
                value={this.state.data}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column
                  body={formatBody}
                  field='text'
                  header='Mail'
                  sortable
                  filter
                />
                <Column
                  body={formatDate}
                  field='createdAt'
                  header='Date'
                  sortable
                  filter
                />
                <Column header='Actions' body={actionBodyTemplate} />
              </DataTable>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Emails);
