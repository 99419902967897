import { Component } from 'react';

// Components
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

// Server API
import { getIframes, deleteIframe } from '../../api/iframes';
import { withRouter } from '../../helpers/routes/withRouter';

class Embedded extends Component {
  state = {
    data: [],
    globalFilter: '',
    selectedCodes: this.props.iframes,
  };

  getEmbedded = async () => {
    const iframes = await getIframes();

    if (!iframes.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: iframes?.message,
      });
    }

    this.setState({ data: iframes.data });
  };

  deleteEmbedded = async (id) => {
    const removeIframe = await deleteIframe(id);

    this.toast.show({
      severity: removeIframe.success ? 'success' : 'error',
      summary: removeIframe.success ? 'Done' : 'Error',
      detail: removeIframe.message,
    });

    await this.getEmbedded();
  };

  async componentDidMount() {
    await this.getEmbedded();
  }

  render() {
    // Import Styling
    require('./Embedded.scss');

    const selectCode = (rowData) => {
      return (
        <Checkbox
          inputId={`code-${rowData._id}`}
          value={{ name: rowData.name, id: rowData._id }}
          onChange={(e) => {
            let selectedCodes = [...this.state.selectedCodes];

            if (!e.checked) {
              selectedCodes.splice(
                this.state.selectedCodes.findIndex(
                  (val) => val.id === rowData._id
                ),
                1
              );
              this.setState({ selectedCodes: selectedCodes });
              this.props.setIframes(selectedCodes);
              return;
            }

            selectedCodes.push(e.value);
            this.setState({ selectedCodes: selectedCodes });
            this.props.setIframes(selectedCodes);
          }}
          checked={this.state.selectedCodes.some(
            (val) => val.id === rowData._id
          )}
        />
      );
    };

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this code?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteEmbedded(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Embedded Codes
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const actionBodyTemplate = (rowData) => (
      <>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />

        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-primary p-ml-2 primary-color'
          onClick={() =>
            this.props.router.navigate(`/codes/edit/${rowData._id}`)
          }
        />
      </>
    );

    return (
      <>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <div className='datatable-responsive-demo'>
          <DataTable
            paginator
            rows={15}
            rowsPerPageOptions={[15, 30, 60]}
            className='p-mt-3 p-datatable-responsive-demo'
            emptyMessage='No codes found.'
            value={this.state.data}
            header={header}
            globalFilter={this.state.globalFilter}
            showGridlines
          >
            <Column field='name' header='Name' sortable filter />

            <Column
              field='code'
              header='Code'
              body={(rowData) => {
                return (
                  <div style={{ wordBreak: 'break-all' }}>
                    {rowData.code.substring(0, 100) +
                      (rowData.code.length > 100 ? '...' : '')}
                  </div>
                );
              }}
              sortable
              filter
            />
            <Column header='Actions' body={actionBodyTemplate} />
            {this.props.popup && (
              <Column header='Select File' body={selectCode} />
            )}
          </DataTable>
        </div>
      </>
    );
  }
}

export default withRouter(Embedded);
