import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Custom Components
import Files from '../../components/Files/Files';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Gravatar from 'react-gravatar';

// Server API
import { getInfo } from '../../api/info';
import { getArticle, updateArticle } from '../../api/blog';
import { getComments, addComment, deleteComment } from '../../api/comments';
import { getObjectByName } from '../../helpers/helper';
import placeholder from '../../assets/img/placeholder.jpg';

class EditArticle extends Component {
  state = {
    // Helper
    fetched: false,

    // ID
    id: this.props.router.params.id,

    // Loader
    loading: false,

    // Dialog
    displayThumbnailDialog: false,
    type: '',

    // Inputs
    name: '',
    description: '',
    updatedDescription: '',
    thumb: '',

    // Comments
    comments: [],
    currentCommentParent: null,
    reply: null,
    fullname: null,
    email: null,
  };

  getArticle = async () => {
    const article = await getArticle(this.state.id);

    if (!article.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: article?.message,
      });
    }

    this.setState({
      name: article.data.title,
      description: article.data.content,
      updatedDescription: article.data.content,
      thumb: article.data.thumbnail,
      fetched: true,
    });
  };

  saveArticle = async () => {
    this.setState({ loading: true });

    if (!this.state.name || !this.state.description || !this.state.thumb) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const article = await updateArticle(this.state.id, {
      title: this.state.name,
      content: this.state.updatedDescription,
      thumbnail: this.state.thumb,
    });

    this.toast.show({
      severity: article.success ? 'success' : 'error',
      summary: article.success ? 'Done' : 'Error',
      detail: article.success
        ? 'Article updated successfully'
        : article?.message,
    });

    this.setState({ loading: false });
  };

  setFile = (type, path) => {
    this.setState({
      thumb: type === 'thumb' ? path : '',
    });
  };

  getComments = async () => {
    const comments = await getComments(this.state.id);

    this.setState({
      comments: comments.data,
    });
  };

  deleteComment = async (id) => {
    const removeComment = await deleteComment(id);

    this.toast.show({
      severity: removeComment.success ? 'success' : 'error',
      summary: removeComment.success ? 'Done' : 'Error',
      detail: removeComment.message,
    });

    await this.getComments();
  };

  sendReply = async () => {
    const sendReply = await addComment({
      name: this.state.fullname,
      comment: this.state.reply,
      email: this.state.email,
      blogID: this.state.id,
      parentID: this.state.currentCommentParent,
    });

    this.toast.show({
      severity: sendReply.success ? 'success' : 'error',
      summary: sendReply.success ? 'Done' : 'Error',
      detail: sendReply.success
        ? 'Reply sent successfully'
        : sendReply?.message,
    });

    await this.getComments();
  };

  getInfo = async () => {
    const info = await getInfo();

    if (!info.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: info?.message,
      });
    }

    this.setState({
      fullname: getObjectByName(info.data, 'option_name', 'full_name')
        .option_value,
      email: getObjectByName(info.data, 'option_name', 'email').option_value,
    });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Article`;
    await this.getArticle();
    await this.getComments();
    await this.getInfo();
  }

  render() {
    // Import Styling
    require('./Blog.scss');

    const comments = this.state?.comments?.map((comment) => {
      const children = this.state?.comments?.map((child) => {
        if (child.parentID && child.parentID === comment._id) {
          return (
            <li key={child._id}>
              <div className='comment reply'>
                <Gravatar email={child.email} size={100} />
                <div className='content'>
                  <h1>{child.name}</h1>
                  {child.email ? (
                    <span>
                      Email:
                      <a href={`mailto:${child.email}`}>{child.email}</a>
                    </span>
                  ) : (
                    ''
                  )}
                  <p>{child.comment}</p>
                </div>
                <Button
                  icon='pi pi-trash'
                  className='p-button-rounded p-button-secondary delete'
                  onClick={() => confirmDelete(child._id)}
                />
              </div>
            </li>
          );
        } else {
          return null;
        }
      });
      if (!comment.parentID) {
        return (
          <li key={comment._id}>
            <div className='comment'>
              <Gravatar email={comment.email} default='monsterid' size={100} />
              <div className='content'>
                <h1>{comment.name}</h1>
                {comment.email ? (
                  <span>
                    Email:
                    <a href={`mailto:${comment.email}`}>{comment.email}</a>
                  </span>
                ) : (
                  ''
                )}
                <p>{comment.comment}</p>

                <div className='p-grid'>
                  <div className='p-col-12 p-md-10'>
                    <InputText
                      placeholder='Write a reply ...'
                      className='w-100 p-p-3'
                      onFocus={() =>
                        this.setState({ currentCommentParent: comment._id })
                      }
                      onChange={(e) => this.setState({ reply: e.target.value })}
                    />
                  </div>
                  <div className='p-col-12 p-md-2'>
                    <Button
                      label='Send'
                      icon='pi pi-send'
                      iconPos='right'
                      className='w-100 primary-color p-p-3'
                      loading={this.state.loading}
                      onClick={this.sendReply}
                    />
                  </div>
                </div>
              </div>
              <Button
                icon='pi pi-trash'
                className='p-button-rounded p-button-secondary delete'
                onClick={() => confirmDelete(comment._id)}
              />
            </div>

            <ol>{children}</ol>
          </li>
        );
      } else {
        return null;
      }
    });

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this comment?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteComment(id),
        reject: () => console.log('No'),
      });
    };

    const footerThumbDialog = (
      <div className='p-pt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          onClick={(e) => this.setState({ displayThumbnailDialog: false })}
          className='primary-color'
        />
      </div>
    );

    return (
      <div className='add-article'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />

        {/* Select Thumbnail */}
        <Dialog
          header='Select Thumbnail'
          maximizable
          visible={this.state.displayThumbnailDialog}
          style={{ width: '75vw' }}
          footer={footerThumbDialog}
          onHide={() => this.setState({ displayThumbnailDialog: false })}
          className='select-thumb-modal'
        >
          <Files
            popup={true}
            setFile={this.setFile}
            type={this.state.type}
            imagesOnly
          />
        </Dialog>

        <Header />
        <div className='container'>
          <Card title='Add Article' className='p-p-4 p-my-5'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-9'>
                <div className='p-grid'>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='name' className='p-d-block'>
                        Title
                      </label>
                      <InputText
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        id='name'
                        className='p-d-block w-100'
                      />
                    </div>
                  </div>
                  <div className='p-col-12 p-md-12'>
                    <div className='p-field'>
                      <label htmlFor='description' className='p-d-block'>
                        Content
                      </label>
                      {this.state.fetched && (
                        <Editor
                          style={{ height: '300px' }}
                          value={this.state.description}
                          onTextChange={(e) =>
                            this.setState({ updatedDescription: e.htmlValue })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-col-12 p-md-3'>
                <div className='p-field'>
                  <label className='p-d-block'>Thumbnail</label>
                  {this.state.thumb ? (
                    <a
                      href={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.thumb}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src={`${process.env.REACT_APP_CONTENT_BASE_URL}${this.state.thumb}`}
                        alt='Icon'
                        className='preview'
                      />
                    </a>
                  ) : (
                    <img src={placeholder} alt='Icon' className='preview' />
                  )}

                  <Button
                    label='Select Thumbnail'
                    className='p-button-outlined w-100'
                    icon='pi pi-plus'
                    iconPos='right'
                    onClick={(e) =>
                      this.setState({
                        displayThumbnailDialog: true,
                        type: 'thumb',
                      })
                    }
                  />
                </div>

                <div className='p-field'>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveArticle}
                  />
                </div>
              </div>
            </div>
          </Card>
          {this.state?.comments?.length ? (
            <Card title='Comments' className='p-p-4 p-mb-5 comments'>
              <ul>{comments}</ul>
            </Card>
          ) : (
            ''
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default EditArticle;
