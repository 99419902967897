import server from "./server";

const getArticles = async () => {
  try {
    const articles = await server.get("/blog");
    return articles.data;
  } catch (error) {
    return error.response.data;
  }
};

const getArticle = async (id) => {
  try {
    const article = await server.get(`/blog/${id}`);
    return article.data;
  } catch (error) {
    return error.response.data;
  }
};

const addArticle = async (data) => {
  try {
    const article = await server.post("/blog", data);
    return article.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateArticle = async (id, data) => {
  try {
    const article = await server.put(`/blog/${id}`, data);
    return article.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteArticle = async (id) => {
  try {
    const articles = await server.delete(`/blog/${id}`);
    return articles.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getArticles, getArticle, addArticle, updateArticle, deleteArticle };
