import server from "./server";

const getProjects = async () => {
  try {
    const projects = await server.get("/portfolio");
    return projects.data;
  } catch (error) {
    return error.response.data;
  }
};

const getProject = async (id) => {
  try {
    const project = await server.get(`/portfolio/${id}`);
    return project.data;
  } catch (error) {
    return error.response.data;
  }
};

const addProject = async (data) => {
  try {
    const project = await server.post("/portfolio", data);
    return project.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProject = async (id, data) => {
  try {
    const project = await server.put(`/portfolio/${id}`, data);
    return project.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProject = async (id) => {
  try {
    const removeProject = await server.delete(`/portfolio/${id}`);
    return removeProject.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getProjects, getProject, addProject, updateProject, deleteProject };
