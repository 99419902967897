import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

// Server API
import { addSkill } from '../../api/skills';
import { withRouter } from '../../helpers/routes/withRouter';

class AddSkill extends Component {
  state = {
    // Loader
    loading: false,

    // Inputs
    name: '',
    percentage: '',
  };

  saveSkill = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (!this.state.name || !this.state.percentage) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const skill = await addSkill({
      name: this.state.name,
      percentage: this.state.percentage,
    });

    this.toast.show({
      severity: skill.success ? 'success' : 'error',
      summary: skill.success ? 'Done' : 'Error',
      detail: skill.success ? 'Skill added successfully' : skill.message,
    });

    if (skill.success) {
      setTimeout(() => {
        _this.props.router.navigate('/skills');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Skill`;
  }

  render() {
    // Import Styling
    require('./Skills.scss');

    return (
      <div className='add-skill'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Add Skill' className='p-p-4 p-my-5'>
            <div className='p-grid'>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='name' className='p-d-block'>
                    Name
                  </label>
                  <InputText
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    id='name'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='percentage' className='p-d-block'>
                    Percentage
                  </label>
                  <InputNumber
                    value={this.state.percentage}
                    onValueChange={(e) =>
                      this.setState({ percentage: e.value })
                    }
                    inputId='percentage'
                    className='p-d-block w-100'
                    mode='decimal'
                    min={0}
                    max={100}
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='percentage' className='p-d-block'>
                    &nbsp;
                  </label>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveSkill}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddSkill);
