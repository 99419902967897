import server from "./server";

const getEmails = async () => {
  try {
    const emails = await server.get("/emails");
    return emails.data;
  } catch (error) {
    return error.response.data;
  }
};

const getEmail = async (id) => {
  try {
    const email = await server.get(`/emails/${id}`);
    return email.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteEmail = async (id) => {
  try {
    const removeEmail = await server.delete(`/emails/${id}`);
    return removeEmail.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getEmails, getEmail, deleteEmail };
