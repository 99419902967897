// Components
import { Card } from 'primereact/card';

const NotFound = () => {
    // Import Styling
    require("./404.scss");

    return (
        <div className="not-found">
            <div className="container">
                <Card>
                    <span>404</span>
                    <h3>This page not found</h3>
                </Card>
            </div>
        </div>
    )
}

export default NotFound;