import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Server API
import { addCategory } from '../../api/categories';
import { withRouter } from '../../helpers/routes/withRouter';

class AddCategory extends Component {
  state = {
    // Loader
    loading: false,

    // Inputs
    category: '',
  };

  saveCategory = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (!this.state.category) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const category = await addCategory({
      name: this.state.category,
    });

    this.toast.show({
      severity: category.success ? 'success' : 'error',
      summary: category.success ? 'Done' : 'Error',
      detail: category.success
        ? 'Category added successfully'
        : category.message,
    });

    if (!this.state.category) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    if (category.success) {
      setTimeout(() => {
        _this.props.router.navigate('/categories');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Category`;
  }

  render() {
    // Import Styling
    require('./Categories.scss');

    return (
      <div className='add-skill'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Add Category' className='p-p-4 p-my-5'>
            <div className='p-grid p-jc-center'>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='category' className='p-d-block'>
                    Category
                  </label>
                  <InputText
                    value={this.state.category}
                    onChange={(e) =>
                      this.setState({ category: e.target.value })
                    }
                    id='category'
                    className='p-d-block w-100'
                    rows={5}
                    autoResize
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='percentage' className='p-d-block'>
                    &nbsp;
                  </label>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveCategory}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddCategory);
