import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Server API
import { addIframe } from '../../api/iframes';
import { withRouter } from '../../helpers/routes/withRouter';

class AddCode extends Component {
  state = {
    // Loader
    loading: false,

    // Inputs
    name: '',
    code: '',
  };

  saveCode = async () => {
    const _this = this;
    this.setState({ loading: true });

    if (!this.state.code || !this.state.name) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const iframe = await addIframe({
      name: this.state.name,
      code: this.state.code,
    });

    this.toast.show({
      severity: iframe.success ? 'success' : 'error',
      summary: iframe.success ? 'Done' : 'Error',
      detail: iframe.success ? 'Code added successfully' : iframe.message,
    });

    if (iframe.success) {
      setTimeout(() => {
        _this.props.router.navigate('/codes');
      }, 1500);
    }

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Add Code`;
  }

  render() {
    // Import Styling
    require('./Embedded.scss');

    return (
      <div className='add-code'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Add Code' className='p-p-4 p-my-5'>
            <div className='p-grid p-jc-center'>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='code' className='p-d-block'>
                    Name
                  </label>
                  <InputText
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    id='name'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-12'>
                <div className='p-field'>
                  <label htmlFor='code' className='p-d-block'>
                    Code
                  </label>
                  <InputTextarea
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                    id='code'
                    className='p-d-block w-100'
                    rows={5}
                    autoResize
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-4'>
                <div className='p-field'>
                  <label htmlFor='percentage' className='p-d-block'>
                    &nbsp;
                  </label>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.saveCode}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddCode);
