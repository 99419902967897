import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

// Server API
import { getProjects, deleteProject } from '../../api/portfolio';
import { getCategories } from '../../api/categories';
import { withRouter } from '../../helpers/routes/withRouter';

class Portfolio extends Component {
  state = {
    data: [],
    categories: [],
    selectedCategory: null,
    globalFilter: '',
  };

  getProjects = async () => {
    const projects = await getProjects();

    if (!projects.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: projects?.message,
      });
    }

    this.setState({ data: projects.data });
  };

  getCategories = async () => {
    const categories = await getCategories();

    if (!categories.success) {
      this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Categories: ' + categories?.message,
      });
    }

    this.setState({ categories: categories.data });
  };

  deleteProject = async (id) => {
    const removeProject = await deleteProject(id);

    this.toast.show({
      severity: removeProject.success ? 'success' : 'error',
      summary: removeProject.success ? 'Done' : 'Error',
      detail: removeProject.message,
    });

    await this.getProjects();
  };

  onCategoryChange = (e) => {
    this.dt.filter(e.value, 'portfolio_category.name', 'equals');
    this.setState({ selectedCategory: e.value });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Portfolio`;
    await this.getProjects();
    await this.getCategories();
  }

  render() {
    // Import Styling
    require('./Portfolio.scss');

    const categories = this.state?.categories?.map((cat) => {
      return cat.name;
    });

    const categoryFilter = (
      <Dropdown
        value={this.state.selectedCategory}
        options={categories}
        onChange={this.onCategoryChange}
        placeholder='Select a Category'
        className='p-column-filter w-100'
        showClear
      />
    );

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this project?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteProject(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Portfolio
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const imageBodyTemplate = (rowData) => {
      return (
        <img
          src={`${process.env.REACT_APP_CONTENT_BASE_URL}${rowData?.thumbnail}`}
          alt={rowData?.path}
          className='thumb-image'
        />
      );
    };

    const actionBodyTemplate = (rowData) => (
      <>
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-secondary'
          onClick={() => confirmDelete(rowData._id)}
        />

        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-primary p-ml-2 primary-color'
          onClick={() =>
            this.props.router.navigate(`/portfolio/edit/${rowData._id}`)
          }
        />
      </>
    );

    return (
      <div className='portfolio'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Portfolio' className='p-p-4 p-my-5'>
            <Button
              label='Add New'
              icon='pi pi-plus'
              className='primary-color'
              onClick={() => this.props.router.navigate('/portfolio/add')}
            />

            <div className='datatable-responsive-demo'>
              <DataTable
                ref={(el) => (this.dt = el)}
                paginator
                rows={15}
                rowsPerPageOptions={[15, 30, 60]}
                className='p-mt-3 p-datatable-responsive-demo'
                emptyMessage='No projects found.'
                value={this.state.data}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column field='name' header='Name' sortable filter />
                <Column
                  body={imageBodyTemplate}
                  field='path'
                  header='Thumbnail'
                />
                <Column
                  field='portfolioCategoryID'
                  header='Category'
                  filterElement={categoryFilter}
                  filterField='portfolio_category.name'
                  body={(rowData) => rowData.portfolio_category?.name}
                  sortable
                  filter
                />
                <Column header='Actions' body={actionBodyTemplate} />
              </DataTable>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Portfolio);
