import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';

// Server API
import { getEmail } from '../../api/emails';
import { withRouter } from '../../helpers/routes/withRouter';

class ViewEmail extends Component {
  state = {
    // ID
    id: this.props.router.params.id,

    // Email Content
    data: {},
  };

  getEmail = async () => {
    const email = await getEmail(this.state.id);

    if (!email.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: email?.response?.data?.message,
      });
    }

    this.setState({ data: email.data });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | View Email`;
    await this.getEmail();
  }

  render() {
    // Import Styling
    require('./Emails.scss');

    const formatDate = new Date(this.state.data.createdAt).toLocaleString();

    return (
      <div className='view-email'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='View Email' className='p-p-4 p-my-5'>
            <Panel header={formatDate}>
              <div dangerouslySetInnerHTML={{ __html: this.state.data.text }} />
            </Panel>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ViewEmail);
