import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

// Server API
import { getAdmin, updateAdmin } from '../../api/admins';
import { withRouter } from '../../helpers/routes/withRouter';

class EditAdmin extends Component {
  state = {
    // ID
    id: this.props.router.params.id,

    // Loader
    loading: false,

    // Inputs
    fullname: '',
    username: '',
  };

  getAdmin = async () => {
    const admin = await getAdmin(this.state.id);

    if (!admin.success)
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: admin?.message,
      });

    this.setState({
      fullname: admin.data.fullname,
      username: admin.data.username,
    });
  };

  updateAdmin = async () => {
    this.setState({ loading: true });

    if (!this.state.fullname || !this.state.username) {
      this.toast.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'All fields are required',
      });
      return this.setState({ loading: false });
    }

    const admin = await updateAdmin(this.state.id, {
      fullname: this.state.fullname,
      username: this.state.username,
    });

    this.toast.show({
      severity: admin.success ? 'success' : 'error',
      summary: admin.success ? 'Done' : 'Error',
      detail: admin.success ? 'Admin updated successfully' : admin.message,
    });

    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Edit Admin`;
    await this.getAdmin();
  }

  render() {
    // Import Styling
    require('./Admins.scss');

    return (
      <div className='edit-admin'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Edit Admin' className='p-p-4 p-my-5'>
            <div className='p-grid p-mt-2'>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='fullname' className='p-d-block'>
                    Full Name
                  </label>
                  <InputText
                    value={this.state.fullname}
                    onChange={(e) =>
                      this.setState({ fullname: e.target.value })
                    }
                    id='fullname'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <label htmlFor='username' className='p-d-block'>
                    Username
                  </label>
                  <InputText
                    value={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    id='username'
                    className='p-d-block w-100'
                  />
                </div>
              </div>
              <div className='p-col-12 p-md-6'>
                <div className='p-field'>
                  <Button
                    label='Save'
                    icon='pi pi-save'
                    iconPos='right'
                    className='w-100 primary-color'
                    loading={this.state.loading}
                    onClick={this.updateAdmin}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(EditAdmin);
