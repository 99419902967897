import { Component } from "react";

// Layout Components
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";

// Components
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

// Server API
import { getHome } from "../../api/home";

class Home extends Component {
  state = {
    data: {},
    globalFilter: "",
  };

  getHome = async () => {
    const home = await getHome();

    if (!home.success) {
      this.toast.show({
        severity: "error",
        summary: "Error",
        detail: home?.message,
      });
    }

    this.setState({ data: home.data });
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Home`;
    await this.getHome();
  }

  render() {
    // Import Styling
    require("./Home.scss");

    const data = this.state.data;

    const formatBody = (rawData) => {
      return <div dangerouslySetInnerHTML={{ __html: rawData.text }} />;
    };

    const formatDate = (rawData) => {
      return new Date(rawData.createdAt).toLocaleString();
    };

    const header = (
      <div className="table-header p-jc-between p-ai-center">
        Last Emails
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="p-pl-5"
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="General Search"
          />
        </span>
      </div>
    );

    return (
      <div className="home">
        <Header />
        <Toast ref={(el) => (this.toast = el)} position="top-right" />

        <div className="container">
          <Card className="p-my-5 p-p-5">
            <div className="p-grid">
              <div className="p-col-12 p-md-4">
                <div className="counter green">
                  <span>{data?.emails}</span> Emails
                </div>
              </div>
              <div className="p-col-12 p-md-4">
                <div className="counter">
                  <span>{data?.articles}</span> Articles
                </div>
              </div>
              <div className="p-col-12 p-md-4">
                <div className="counter light">
                  <span>{data?.projects}</span> Projects
                </div>
              </div>
            </div>
            <div className="datatable-responsive-demo">
              <DataTable
                className="p-mt-5 p-datatable-responsive-demo"
                emptyMessage="No emails found."
                value={data?.lastEmails}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column body={formatBody} field="text" header="Mail" sortable />
                <Column
                  body={formatDate}
                  field="createdAt"
                  header="Date"
                  sortable
                />
              </DataTable>
            </div>
          </Card>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Home;
