import server from "./server";

const getHome = async () => {
  try {
    const home = await server.get("/home");
    return home.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getHome };
