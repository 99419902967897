import { Component } from 'react';

// Layout Components
import Header from '../../components/layout/Header/Header';
import Footer from '../../components/layout/Footer/Footer';

// Components
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';

// Server API
import { getAdmins, deleteAdmin } from '../../api/admins';
import { withRouter } from '../../helpers/routes/withRouter';

class Admins extends Component {
  state = {
    data: [],
    globalFilter: '',
  };

  getAdmins = async () => {
    const admins = await getAdmins();

    if (!admins.success) {
      return this.toast.show({
        severity: 'error',
        summary: 'Error',
        detail: admins?.message,
      });
    }

    this.setState({ data: admins.data });
  };

  deleteAdmin = async (id) => {
    const removeAdmin = await deleteAdmin(id);

    this.toast.show({
      severity: removeAdmin.success ? 'success' : 'error',
      summary: removeAdmin.success ? 'Done' : 'Error',
      detail: removeAdmin.message,
    });

    await this.getAdmins();
  };

  async componentDidMount() {
    document.title = `${process.env.REACT_APP_NAME} | Admins`;
    await this.getAdmins();
  }

  render() {
    // Import Styling
    require('./Admins.scss');

    const confirmDelete = (id) => {
      confirmDialog({
        message: 'Are you sure you want to delete this admin?',
        header: 'Confirm Delete',
        icon: 'pi pi-exclamation-triangle',
        accept: () => this.deleteAdmin(id),
        reject: () => console.log('No'),
      });
    };

    const header = (
      <div className='table-header p-jc-between p-ai-center'>
        Admins
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText
            type='search'
            className='p-pl-5'
            value={this.state.globalFilter}
            onChange={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder='General Search'
          />
        </span>
      </div>
    );

    const actionBodyTemplate = (rowData) => {
      if (rowData._id !== 1) {
        return (
          <>
            <Button
              icon='pi pi-trash'
              className='p-button-rounded p-button-secondary'
              onClick={() => confirmDelete(rowData._id)}
            />

            <Button
              icon='pi pi-pencil'
              className='p-button-rounded p-button-primary p-ml-2 primary-color'
              onClick={() =>
                this.props.router.navigate(`/admins/edit/${rowData._id}`)
              }
            />
          </>
        );
      }
    };

    return (
      <div className='admins'>
        <Toast ref={(el) => (this.toast = el)} position='top-right' />
        <Header />
        <div className='container'>
          <Card title='Admins' className='p-p-4 p-my-5'>
            <Button
              label='Add New'
              icon='pi pi-plus'
              className='primary-color'
              onClick={() => this.props.router.navigate('/admins/add')}
            />

            <div className='datatable-responsive-demo'>
              <DataTable
                paginator
                rows={15}
                rowsPerPageOptions={[15, 30, 60]}
                className='p-mt-3 p-datatable-responsive-demo'
                emptyMessage='No admins found.'
                value={this.state.data}
                header={header}
                globalFilter={this.state.globalFilter}
                showGridlines
              >
                <Column field='fullname' header='Full Name' sortable filter />
                <Column field='username' header='Username' sortable filter />
                <Column header='Actions' body={actionBodyTemplate} />
              </DataTable>
            </div>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Admins);
